
  export default {
    name: `DialogPreviewFile`,
    data() {
      return {
        file: undefined,
        toggleDialog: false,
      }
    },
    props: {
      previewFileUrl: {
        type: String,
        default: null
      }
    },
    methods: {
      handlerClick() {
        this.toggleDialog = true
      }
    }
  }

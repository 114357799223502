
  import { mapGetters } from 'vuex'
  import StepperStepSummary from '~/components/marketplace/new_check/stepper_summary.vue'
  import StepperStepProduct from '~/components/marketplace/new_check/stepper_product.vue'
  import StepperStepCandidate from '~/components/marketplace/new_check/stepper_candidate.vue'

  const STEPS = {
    stepperStepProduct: 1,
    stepperStepCandidate: 2,
    stepperStepSummary: 3
  }

  export default {
    name: `CreateCheckPage`,
    components: {
      StepperStepCandidate,
      StepperStepProduct,
      StepperStepSummary
    },
    data() {
      return {
        STEPS,
        toggleStep: STEPS.stepperStepProduct,
        completedSteps: []
      }
    },
    computed: {
      ...mapGetters({
        categories: `marketplace/categories`,
        reasons: `marketplace/reasons`,
        dossiers: `marketplace/dossiers`,
        selectedChecks: `marketplace/selectedChecks`,
        candidateFormsAnswers: `marketplace/candidateFormsAnswers`
      }),
      ready() {
        return !this.$fetchState.pending || !(!this.dossiers || !this.reasons || !this.categories)
      }
    },
    watch: {
      selectedChecks() {
        !this.selectedChecks.length && this.updateCompleted(STEPS.stepperStepProduct)
      },
      candidateFormsAnswers: {
        deep: true,
        async handler() {
          const isValid = await this.$refs.stepperStepCandidate.isObsValid()
          this.$refs.stepperStepCandidate.resetObsValidation()
          !isValid && this.updateCompleted(STEPS.stepperStepCandidate)
        }
      }
    },
    async fetch() {
      await this.$refs?.stepperStepProduct
      await this.$refs?.stepperStepProduct.$refs.menuDossierReason.$fetchState.pending
      await this.$refs?.stepperStepProduct.$refs.menuCategories.$fetchState.pending
    },
    methods: {
      removeCompletedStep(step) {
        this.completedSteps = this.completedSteps.filter((x) => x !== step)
      },
      openStep(step) {
        this.toggleStep = step
      },
      nextStep(step) {
        this.completedSteps.push(step - 1)
        this.toggleStep = step
      },
      onOrderCompleted(checkUUID) {
        this.$store.dispatch(`accounting/getSettings`, { companyTag: this.$auth.user.current_company })
        this.$router.push({
          path: `/marketplace/checks/${checkUUID}`,
          query: { order: `success` }
        })
      },
      onOrderPending() {
        this.freeze()
      },
      onOrderFailed() {
        this.defrost()
      },
      updateCompleted(currentStep) {
        this.completedSteps = this.completedSteps.filter((x) => x < currentStep)
      },
      freeze() {
        this.$refs.stepperStepProduct.freeze()
        this.$refs.stepperStepCandidate.freeze()
        this.$refs.stepperStepSummary.freeze()
      },
      defrost() {
        this.$refs.stepperStepProduct.defrost()
        this.$refs.stepperStepCandidate.defrost()
        this.$refs.stepperStepSummary.defrost()
      },
      clearAll() {
        this.toggleStep = STEPS.stepperStepProduct
        this.$refs.stepperStepProduct.clearStep()
        this.$refs.stepperStepCandidate.clearStep()
      }
    }
  }

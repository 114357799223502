
  import { mapGetters } from 'vuex'
  import InfiniteLoading from 'vue-infinite-loading'
  import SelectionCard from '~/components/marketplace/SelectionCard.vue'
  import MenuChecksFilter from '~/components/marketplace/new_check/MenuChecksFilter.vue'
  import DialogCheckLanguage from '~/components/marketplace/new_check/DialogLanguage.vue'
  import ProductInformation from '~/components/marketplace/new_check/ProductInformation.vue'

  export default {
    name: `MenuChecks`,
    components: {
      DialogCheckLanguage,
      MenuChecksFilter,
      SelectionCard,
      InfiniteLoading,
      ProductInformation,
    },
    data() {
      return {
        page: 1,
        checksData: [],
        ProductInformation,
      }
    },
    computed: {
      ...mapGetters({
        selectedChecks: `marketplace/selectedChecks`,
        selectedCategory: `marketplace/selectedCategory`,
        selectedFilters: `marketplace/selectedFilters`,
        checks: `marketplace/checks`,
        totalCredits: `marketplace/totalCredits`,
      }),
      availableCredits(state) {
        return this.$store.getters[`accounting/availableCredits`] ?? this.$store.dispatch(`accounting/getSettings`, { companyTag: this.$auth.user.current_company })
      },
      lastSelected() {
        return !!this.selectedChecks ? this.selectedChecks[0] : undefined
      },
      remainCredits() {
        return this.availableCredits - this.totalCredits
      }
    },
    watch: {
      selectedCategory: {
        handler() {
          this.reset()
        }
      },
      checks: {
        handler(newValue) {
          const _newValue = newValue?.data ?? []
          this.checksData = this.page > 1
            ? [...this.checksData, ..._newValue]
            : _newValue
        }
      }
    },
    async destroyed() {
      await this.$store.commit(`marketplace/defaultChecks`)
      await this.$store.commit(`marketplace/defaultSelectedChecks`)
    },
    methods: {
      getDisabled(check) {
        const creditCheck = check.credits > this.remainCredits
        const alreadySelected = this.selectedChecks?.filter((i) => i.id === check.id).length
        const multipleAllowed = !!this.selectedCategory?.select_multiple
        const someSelectedChecks = Boolean(this.selectedChecks.length)

        return (creditCheck && !alreadySelected) || (!multipleAllowed && someSelectedChecks && !alreadySelected)
      },
      getPriceString(check) {
        const translatedCredits = this.$t(`components.credits`)
        let str = `${translatedCredits} : `
        if (check.credits !== check.original_price) {
          str += `<del style="color: grey;">${check.original_price}</del> ${check.credits}`
        } else {
          str += check.credits
        }
        return str
      },
      getFilters() {
        const page = this.page === 1 ? {} : { page: [this.page] }
        return { ...this.selectedFilters, ...page}
      },
      async getChecks() {
        await this.$store.dispatch(`marketplace/getChecks`, { filters: this.getFilters() })
      },
      async select(check) {
        await this.$store.dispatch(`marketplace/addSelectedCheck`, check)
      },
      async applyFilter() {
        this.reset()
        await this.getChecks()
      },
      infiniteHandler($state) {
        if (this.page >= this.checks.pagination.total_pages){
          $state.complete()
          return
        }
        this.page += 1
        this.getChecks().then(() => $state.loaded())
      },
      reset() {
        this.page = 1
        this.$refs.infiniteLoading?.stateChanger.reset()
      },
      async clearValues() {
        this.reset()
        await this.$store.commit(`marketplace/defaultSelectedChecks`)
        await this.$store.commit(`marketplace/defaultChecks`)
      }
    }
  }
